* {
	-webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: 'Prata';
	src: url('../fonts/prata-regular-webfont.woff2') format('woff2'),
		url('../fonts/prata-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Black';
	src: url('../fonts/montserrat-black-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-black-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Medium';
	src: url('../fonts/montserrat-medium-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat SemiBold';
	src: url('../fonts/montserrat-semibold-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-semibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat SemiBoldItalic';
	src: url('../fonts/montserrat-semibolditalic-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-semibolditalic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Light';
	src: url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Italic';
	src: url('../fonts/montserrat-italic.woff2') format('woff2'),
		url('../fonts/montserrat-italic.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Thin Italic';
	src: url('../fonts/montserrat-thin-italic.woff2') format('woff2'),
		url('../fonts/montserrat-thin-italic.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Thin Bold Italic';
	src: url('../fonts/montserrat-thin-500-italic.woff2') format('woff2'),
		url('../fonts/montserrat-thin-500-italic.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Thin 300 Italic';
	src: url('../fonts/montserrat-300-italic.woff2') format('woff2'),
		url('../fonts/montserrat-300-italic.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Opensans Light';
	src: url('../fonts/opensans-light-webfont.woff2') format('woff2'),
		url('../fonts/opensans-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Times New Roman Regular';
	/* src: url('../fonts/timesnewroman.ttf') format('truetype'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	src: url('../fonts/timesnewroman.woff2') format('woff2'),
		url('../fonts/timesnewroman.woff') format('woff');
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Montserrat', sans-serif;
}

h1 {
	font-weight: bolder;
	font-size: 42px;
}

h2 {
	font-size: 34px;
}

h3 {
	font-size: 26px;
	font-weight: initial;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

p {
	margin: 0 0 1rem 0;
	font-size: 16px;
}

p strong {
	font-family: 'Montserrat Light';
}

a {
	color: #c7a652;
	text-decoration: none;
}

a:hover {
	color: #c7a652;
	text-decoration: underline;
}

::-webkit-input-placeholder {
	/* Edge */
	color: #999;
}

:-ms-input-placeholder {
	/* Internet Explorer */
	color: #999;
}

::placeholder {
	color: #999;
}

#footer-links {
	padding-bottom: 40px;
}

.prata {
	font-family: 'Prata';
	font-size: 52px;
}

.prata2 {
	font-family: 'Prata Regular';
	font-size: 33px;
}

.montBlack {
	font-family: 'Montserrat Black';
	font-size: 50px;
}

.montBlack2 {
	font-family: 'Montserrat Black';
	font-size: 36px !important;
}

.montSemi {
	font-family: 'Montserrat SemiBold';
	font-size: 58px;
}

.montLight {
	font-family: 'Montserrat Light';
	font-size: 12px;
}

.montBold {
	font-family: 'Montserrat Bold';
}

.montMedium {
	font-family: 'Montserrat Medium' !important;
	font-size: 26px !important;
	line-height: 1.4em !important;
	padding-bottom: 20px !important;
}

.align-center {
	text-align: center;
}

.divider {
	margin: 20px auto 30px auto;
	border-radius: 5px;
}

.inner {
	width: 100%;
	max-width: 1366px;
	margin: 0 auto;
}

.inner-big {
	width: 100%;
	max-width: 1366px;
	margin: 0 auto;
}

.outer-section {
	background: #c7a652;
	width: 100%;
}

.row {
	float: left;
	width: 33%;
}

#navbar {
	position: fixed;
	z-index: 99999999;
	background: #fff;
	width: 100%;
	height: 135px;
	height: auto;
	max-height: 135px;
	top: 0;
	transition: top 0.5s;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#wrapper {
	font-size: 0;
	padding-top: 118px;
}

/*** Header ***/
#logo {
	display: inline;
}

#logo a img {
	width: 285px;
	height: auto;
}

#main-menu {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

header .inner {
	padding: 10px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

header .header-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

a.btn-wrapper {
	text-decoration: none;
}

div.d-btn {
	min-width: 182px;
	min-height: 70px;
	/* max-width: 182px;*/
	max-height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	background-color: #c7a652;
}

div.d-btn p {
	margin: 0;
	padding: 0;
	color: #000000;
	font-size: 16px;
}

div.btn-quote p {
	margin: 0;
	padding: 0;
	color: #000000;
	font-size: 16px;
}

div.btn-quote {
	-webkit-box-shadow: 0px 7px 64px -16px #c7a652;
	-moz-box-shadow: 0px 7px 64px -16px #c7a652;
	box-shadow: 0px 7px 64px -16px #c7a652;
	line-height: 22px;
}

div.btn-quote:hover p {
	color: #ffffff;
}

div.btn-quote:hover {
	background-color: #000000;
}

div.btn-quote:hover span.icon-quote {
	background-image: url('../images/quote-white.png');
}

/* learn more button */
div.btn-learn-more p {
	margin: 0;
	padding: 0;
	color: #ffffff;
	font-size: 16px;
}

div.btn-learn-more {
	line-height: 22px;
	width: 182px;
	margin-top: 48px;
}

div.btn-learn-more:hover p {
	color: #000;
	text-decoration: none;
}

div.btn-learn-more:hover {
	background-color: #000000;
	background: #fff;
}

#contact-mobile-wrapper {
	margin: 0;
	padding: 0;
}

#content p#header-content {
	text-align: center;
}

/**** New Menu CSS **/
#main-menu ul li {
	line-height: 50px;
	padding: 20px 0;
}

#main-menu ul li a {
	color: #333333;
	display: block;
	font-size: 14px;
	text-decoration: none;
	line-height: 22px;
}

#main-menu ul li a:hover {
	color: #c5a44b;
	text-decoration: none;
}

#main-menu ul {
	/* all lists... */
	margin: 0;
	background: #fff;
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	width: 90%;
}

#main-menu ul li.quote {
	line-height: 0px;
}

#main-menu ul ul {
	/* lists within lists... */
	position: absolute;
	/* place them over the top of everything */
	display: none;
	/* hide 'em */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
	width: 220px;
	z-index: 100;
	border-top: 4px solid #c5a44b;
	margin-top: 20px !important;
	margin-left: -3px;
}

#main-menu ul ul li {
	padding: 0;
	border-bottom: 1px solid #e9e9e9;
	width: 100%;
	line-height: 0;
}

#main-menu ul ul li:last-child {
	border-bottom: none;
}

#main-menu ul ul li a {
	padding: 19px 40px 18px 20px;
}

#main-menu li:hover ul {
	/* when list items are hovered over, do this to lists contained within them... */
	display: block;
	/* show 'em */
}

#main-menu ul ul li a:hover {
	background: #000;
}

.no-webp .quote:hover a span {
	background-image: url('../images/quote-white.png');
}

.webp .quote:hover a span {
	background-image: url('../images/quote-white.webp');
}

.icon-quote {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 4px;
	margin-bottom: 2px;
}

.webp .icon-quote {
	background-image: url('../images/quote.webp');
}

.no-webp .icon-quote {
	background-image: url('../images/quote.png');
}

.icon-quote-white {
	background-image: url('../images/quote-white.png');
	display: inline-block;
	width: 20px;
	height: 20px;
	background-size: contain;
	background-repeat: no-repeat;
}

.quote a {
	padding: 14px 22px 20px 26px !important;
	font-size: 16px !important;
	color: #000 !important;
	font-family: 'Montserrat Medium';
}

header i {
	border: solid #636363;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	position: relative;
	top: -2px;
}

.fa {
	border: 0;
}

.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.upicon {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #c5a44b;
	top: -8px;
	left: 2px;
	position: absolute;
}

.menudrop:hover i {
	border-color: #c5a44b;
}

/*** End Header ***/
/*** Banner ***/
#banner {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

#banner-slider {
	width: 100%;
	display: inline-block;
	position: relative;
}

#banner-slider:hover {
	cursor: pointer;
}

.bannerSlides img {
	width: 100%;
}

#banner .text hr {
	margin-bottom: 24px;
	text-align: center;
}

#banner .text h1 {
	color: #c7a652;
	font-family: 'Montserrat Bold', sans-serif;
}

#banner-info {
	width: 100%;
	margin: 0 auto;
	background-color: #000;
	position: relative;
	top: -34px;
	border-radius: 40px;
	display: inline-block;
	color: #fff;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#banner-info .mid-row {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 0px;
}

#banner-info ul {
	list-style: none;
}

#banner-info ul li {
	color: #fff;
	font-size: 14px;
}

#banner-info i {
	font-size: 22px;
	position: relative;
	top: 0px;
}

#banner-info span {
	position: relative;
	top: -3px;
	line-height: 1.7em;
}

#banner img {
	width: 100%;
	height: 100%;
}

/*** about-us ***/
.bg-50-gray {
	background: linear-gradient(90deg, #dfdfdf 50%, #ffffff 50%);
}

.full-height {
	height: 100%;
}

#headline-about-us {
	height: 100%;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	flex-grow: 1;
	background: url('../images/about-us-images/header-img-about-us.png') bottom
		right no-repeat;
	background-size: contain;
}

#headline-about-us .content {
	width: 25%;
	padding-bottom: 33px;
}

#headline-about-us .content .divider-2.divider-gold {
	margin: 0;
}

#headline-about-us .content h1 {
	font-size: 56px;
	line-height: 1.6em;
}

#headline-about-us .content p {
	font-family: 'Montserrat';
	line-height: 1.4em;
	margin: 8px 0 24px 0;
	font-size: 24px;
}

#headline-about-us .content div.callback-button {
	margin-top: 0px;
	margin-left: 0;
}

.about-us-section {
	padding-top: 120px;
	padding-bottom: 120px;
	display: flex;
}

.about-us-section .content {
	display: flex;
	flex-direction: column;
	align-self: flex-end;
}

#about-us-section-service .about-us-section .content {
	max-height: 600px;
}

.about-us-section .section-col {
	flex-direction: row;
	display: flex;
}

.section-content-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.about-us-section hr.divider-2.divider-gold {
	margin: 0;
	padding: 0;
}

.bg-about-us-gray {
	background-color: #dfdfdf;
}

.about-us-section .section-header {
	background-color: #000000;
	align-items: flex-end;
	justify-content: center;
	color: #ffffff;
	margin: 0;
	display: flex;
	flex-direction: column;
	text-align: right;
	padding: 40px 40px 24px 40px;
}

.about-us-section .section-header h3 {
	font-family: 'Montserrat Bold';
	line-height: 1.4em;
	padding-left: 56px;
	font-size: 40px;
	margin-top: 24px;
	margin-bottom: 8px;
}

#about-us-section-help .content {
	max-height: -moz-calc(636px - 56px);
	max-height: -webikit-calc(636px - 56px);
	max-height: calc(636px - 56px);
}

#about-us-section-story .content {
	max-height: -moz-calc(456px - 56px);
	max-height: -webikit-calc(456px - 56px);
	max-height: calc(456px - 56px);
}

#about-us-section-service .content {
	max-height: -moz-calc(600px - 56px);
	max-height: -webikit-calc(600px - 56px);
	max-height: calc(600px - 56px);
}

.about-us-section .section-content {
	padding: 30px 32px;
	margin: 10px 8px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.rtl {
	direction: rtl;
}

.about-us-section .section-content p {
	font-family: 'Montserrat Light';
	text-align: right;
	line-height: 2em;
}

.about-us-section .callback-button {
	margin-top: 16px;
	margin-right: 0px;
	display: inline-block;
	justify-content: flex-end;
}

#about-us-section-help {
	padding-top: 87px;
}

#about-us-section-story .section-header {
	align-items: flex-start;
}

#about-us-section-story .section-header h3 {
	padding-left: 0;
}

#about-us-section-story p {
	text-align: left;
}

#about-us-section-story .section-content-wrapper img {
	width: 100%;
}

#about-us-banner > #banner-mid-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 0px;
	padding-left: 16px;
	padding-right: 16px;
}

#about-us-banner > #banner-mid-content .divider-2 {
	margin: 0;
	z-index: 9999;
}

#about-us-banner > #banner-mid-content h2 {
	color: #ffffff;
	font-size: 40px;
	line-height: 1.4em;
	margin: 24px 0;
	z-index: 9999;
}

#about-us-banner > #banner-mid-content div.callback-button {
	z-index: 9999;
}

#about-us-banner > #banner-mid-content div.callback-button:hover a {
	color: #000000;
	text-decoration: none;
}

#about-us-banner > #banner-mid-content div.callback-button:hover {
	background-color: #ffffff;
	cursor: pointer;
	z-index: 9999;
}

#about-us-banner > #banner-mid-content::before {
	content: '';
	width: 100%;
	height: 340px;
	position: absolute;
	background-image: url('https://securecash.com.au/images/banner/banner-people.webp');
	background-repeat: no-repeat;
	filter: grayscale(100%);
	background-size: cover;
	background-position: center;
}

#about-us-banner-bottom {
	height: 340px;
	width: 100%;
}

#about-us-banner-bottom::before {
	content: '';
	height: 340px;
	width: 100%;
	position: absolute;
	background: url(https://securecash.com.au/images/team.webp) left 34% no-repeat;
	background-size: cover;
	filter: brightness(0.5);
}

.no-webp #about-us-banner-bottom::before {
	content: '';
	height: 340px;
	width: 100%;
	position: absolute;
	background: url(https://securecash.com.au/images/team.jpg) left 34% no-repeat;
	background-size: cover;
}

#about-us-banner-bottom div {
	position: relative;
	z-index: 9999;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	color: white;
	height: 100%;
}

#about-us-banner-bottom div h2 {
	font-size: 40px;
	line-height: 2em;
}

#about-us-banner-bottom div hr {
	margin: 6px 0 36px 0;
	padding: 0;
}

#about-us-team-slider {
	padding-top: 120px;
	padding-bottom: 120px;
	position: relative;
}

.team-carousel {
	display: flex;
	width: 100%;
	position: relative;
	transition: 1s;
	left: 0;
	height: 672px;
	flex-direction: row;
}

.team-item-wrapper {
	position: relative;
	height: 100%;
	min-width: 600px;
	margin-right: 160px;
}

.team-item-about-content {
	background-color: white;
	border-radius: 8px;
	position: absolute;
	top: 50px;
	right: 0;
	width: -moz-calc(100% - 50px);
	width: -webikit-calc(100% - 50px);
	width: calc(100% - 50px);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	height: -moz-calc(100% - 50px);
	height: -webikit-calc(100% - 50px);
	height: calc(100% - 50px);
}

.team-carousel .team-item-wrapper .item-container .member-info p.prata {
	margin-bottom: 24px;
}

.team-carousel .team-item-wrapper .item-container .member-info .email-info {
	padding: 8px 0px;
}

.team-carousel .team-item-wrapper .item-container .member-info .mail-icon {
	margin: 0;
	padding: 0;
}

.team-carousel .team-item-wrapper .item-container .member-info p {
	font-size: 16px;
}

.team-carousel .team-item-wrapper .item-container .member-info h4 {
	font-size: 24px;
}

.team-carousel .team-item-wrapper .item-container .member-info {
	padding: 20px;
}

#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info {
	background-color: #b9984b;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	p,
#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	a,
#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	p.prata,
#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	h4 {
	color: #ffffff;
}

#about-us-team-slider
	.team-carousel-wrapper
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	p,
#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	p.prata {
	font-weight: 800;
}

#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	.mail-icon,
#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	.social-media
	img {
	filter: brightness(10);
}

.team-item-about-content p {
	margin-left: 55%;
	padding-right: 16px;
	font-size: 16px;
	line-height: 1.6em;
	font-family: 'Montserrat Light';
}

.team-item-about-content .wrapper {
	border-right: 4px solid #b9984b;
}

.team-carousel .team-item-wrapper .item-container {
	position: relative;
	width: 300px;
	margin: 0;
	z-index: 9999;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#about-team-scroll-controll {
	width: 48px;
	height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: -moz-calc(50% - 75px);
	top: -webikit-calc(50% - 75px);
	top: calc(50% - 75px);
	right: -moz-calc((100% - 1366px) / 6);
	right: -webikit-calc((100% - 1366px) / 6);
	right: calc((100% - 1366px) / 6);
}

#about-us-team-slider > .inner .team-carousel-wrapper {
	overflow: hidden;
}

#about-team-scroll-btn-up {
	background-image: url(../images/about-us-images/arrow.png);
	background-repeat: no-repeat;
	transform: rotate(-90deg);
	width: 48px;
	height: 29px;
}

#about-team-scroll-btn-down {
	background-image: url(../images/about-us-images/arrow-disable.png);
	background-repeat: no-repeat;
	width: 48px;
	height: 29px;
	transform: rotate(90deg);
}

#about-team-scroll-btn-up:hover,
#about-team-scroll-btn-down:hover {
	cursor: pointer;
}

/*** about-us ***/
/*** Content ***/
.content-bg {
	background-image: url('../images/mainbg.jpg');
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	display: inline-block;
	margin-top: 47px;
}

.section-wrapper {
	background-image: url('../images/mainbg.jpg');
	background-repeat: no-repeat;
	background-position: top;
}

.section-wrapper-repeat {
	background-image: url('../images/mainbg.jpg');
	background-repeat: repeat;
	background-position: top;
}

#content {
	min-height: 400px;
	margin: 0 auto;
}

#content .p-header h4 {
	margin-bottom: 0;
}

#content p {
	font-family: 'Montserrat Light';
	font-size: 16px;
	line-height: 2em;
	text-align: left;
	margin: 0;
	margin-top: 16px;
}

#main-content-scroll p {
	text-align: center;
}

#content .center-content {
	width: 70%;
	margin: 0 auto;
}

#content .center-content-text {
	width: 70%;
	margin: 0 auto;
}

#content .leftside-column p,
#content .leftside-column {
	text-align: left;
}

.featured-content {
	padding-right: 80px;
	width: 683px;
	float: right;
}

.header-gold {
	font-family: 'Times New Roman';
	font-size: 50px;
	font-weight: 400;
}

.header-times {
	font-family: 'Prata';
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 16px;
	line-height: 1em;
}

#content-logo {
	margin: 0 auto;
	display: block;
}

.color-gold {
	color: #957433;
}

.content-columns {
	width: 100%;
	display: flex;
	padding-top: 100px;
}

.content-columns .rightside-column,
.content-columns .leftside-column {
	float: left;
	width: 50% !important;
}

.content-columns .leftside-column p {
	text-align: justify;
}

.content-columns .leftside-column h3 {
	margin-bottom: 40px;
}

.content-columns .rightside-column iframe {
	width: 100%;
	height: 100%;
}

.main-content-scroll {
	overflow: auto;
	height: 390px;
	--scrollbarBG: #ebebeb;
	--thumbBG: #808080;
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
	direction: rtl;
	background: #fff;
	padding-left: 40px;
	padding-right: 40px;
}

.main-content-scroll .scrollable-content {
	direction: ltr;
}

/*** End of Content **/
/*** Content Pages Style ***/
.content-wrapper {
	width: 100%;
	margin: 0 auto -5px auto;
	display: block;
	clear: both;
	padding-top: 130px;
}

.content-wrapper #content {
	width: 100%;
	padding-top: 0px;
}

.header-leftcontent,
.header-rightcontent {
	width: 50%;
	float: left;
}

.headercontent {
	background: #000;
	min-height: 568px;
}

.header-leftcontent {
	color: #fff;
	padding: 60px 40px 0px 60px;
	text-align: left;
}

.header-rightcontent img,
.service-content-right img,
.benefits-box-left img {
	width: 100%;
}

.service-content-left,
.service-content-right,
.benefits-box-left,
.benefits-box-right {
	width: 50%;
	float: left;
}

/*** End Content Page Style ***/
/*** Banner Mid **/
#banner-mid {
	background-size: cover;
	background-repeat: no-repeat;
	height: 340px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	-o-background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size: cover;
}

#banner-mid #content-counter-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.webp #banner-mid {
	background-image: url('../images/banner/banner-people.webp');
	/* background-image: url('../images/banner/home-statistics.jpg'); */
	background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		),
		url('../images/banner/home-statistics.jpg');
	background-position: center center;
}

.no-webp #banner-mid {
	/* background-image: url('../images/banner/banner-people.jpg'); */
	/* background-image: url('../images/banner-people.jpg'); */
	background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		),
		url('../images/banner/home-statistics.jpg') !important;
	background-position: center center;
}

.service-container .divider {
	margin: 20px 0px 30px 0px !important;
	width: 20%;
	height: 4px;
	background: #fff;
}

h2.montSemiBold {
	color: #c7a652;
	font-size: 40px !important;
	line-height: 1em;
	font-family: 'Montserrat Bold';
	margin-bottom: 24px;
}

h3.prata2 {
	font-size: 32px !important;
	line-height: 1em;
	margin-bottom: 24px;
}

.service-container p.small,
.right-contact-row p.small,
.bottom-row-left .row-container p.small {
	line-height: 1em;
	margin: 0;
	padding: 0;
}

.bottom-row-left .row-container h2.montSemiBold {
	color: #ffffff;
}

.right-contact-row p.small {
	font-family: Montserrat;
	font-weight: 400;
}

.right-contact-row .divider-2 {
	margin: 0 0 24px 0;
}

.right-contact-row h3.prata2 {
	margin-bottom: 16px;
}

#banner-mid-content {
	background-size: cover;
	background-repeat: no-repeat;
	height: 340px;
	width: 100%;
	margin: 0 auto;
	padding-top: 40px;
	position: relative;
}

/* aaaaaaaa */
#banner-mid-content::before {
	content: '';
	width: 100%;
	height: 340px;
	position: absolute;
	background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		),
		url(../images/banner/mobilenumbersbg.jpg) !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

#hero-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 80vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

#hero-image h1 {
	text-align: center;
	line-height: 61px;
	font-size: 50px;
	text-shadow: 0.01em 0.05em 0.08em rgba(12, 12, 12, 0.23);
	padding: 0 25px;
}

#hero-image .center-content-divider {
	background: #fff;
}

#hero-image .cta-box {
	margin-top: 80px;
	width: 545px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.webp #banner-mid-content:before {
	/* background-image: url('../images/banner/banner-people.webp') !important; */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(../images/team.webp) center -300px !important;
}

.no-webp #banner-mid-content:before {
	/* background-image: url('../images/banner/banner-people.jpg') !important; */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(../images/team.jpg) center -300px !important;
}

.webp #about-us-banner #banner-mid-content:before {
	/* background-image: url('../images/banner/banner-people.webp') !important; */
	background: url(../images/banner/banner-people.webp) center center !important;
}

.no-webp #banner-mid-content:before {
	background: url(../images/banner/banner-people.jpg) center center !important;
}

#banner-mid .mid-row {
	padding-left: 0px;
}

.mid-row {
	padding-left: 70px;
	width: 33%;
	float: left;
	text-align: center;
	position: relative;
}

.mid-row img {
	height: 60px;
	padding-bottom: 10px;
}

.mid-row h4 {
	padding-bottom: 30px;
	color: #c7a652;
	font-size: 40px;
	font-family: 'Montserrat Black';
}

.mid-row p {
	color: #fff;
	font-size: 16px;
	margin-bottom: 0;
	padding-bottom: 0;
}

.vl {
	border-left: 2px solid #fff;
	border-right: 2px solid #fff;
	height: 112px;
	position: absolute;
	width: 100%;
	margin-top: 16px;
}

.mid-row-divider {
	height: 100px;
	width: 2px;
	background-color: #ffffff;
}

/*** End Banner Mid ***/
/*** Content Mid ***/
#content-middle {
	background-color: #1a1a1a;
	display: inline-block;
	width: 100%;
	margin: 0 auto;
}

#content-middle div.inner.content-middle-wraper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 100px 0;
}

.right-row-content-middle {
	flex: 3;
	margin-left: 40px;
}

.left-row-content-middle hr.divider-2.divider-white {
	margin-top: 0;
}

.left-row-content-middle {
	flex: 2;
}

.text-heading {
	padding-top: 20px;
	font-size: 16px;
	padding-bottom: 1rem;
}

.left-row-content-middle {
	width: 35%;
	color: #ffffff;
}

.left-row-content-middle .service-container {
	line-height: 2em;
	margin: 0;
	font-size: 16px !important;
	color: #fff;
}

.row-container h1 {
	color: #c7a652;
}

.right-row {
	float: left;
	width: 65%;
	padding-left: 40px;
}

.video {
	width: 1024px;
	position: relative;
	margin: 0 auto;
	top: 470px;
}

.video iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.video h5 {
	font-size: 20px;
	color: #c7a652;
	text-align: center;
	position: relative;
	z-index: -1;
}

/*** End Content Mid ***/
/*** Bottom Content ***/
#bottom-content {
	display: grid;
	grid-template-columns: 50% 50%;
	clear: both;
	position: relative;
	height: 600px;
	width: 100%;
	margin: 100px 0 auto;
}

.bottom-row-left {
	background: #c7a652;
	position: relative;
}

.bottom-row-right {
	background-repeat: no-repeat;
	background-size: cover;
}

.webp .bottom-row-right {
	background-image: url('../images/team.webp');
}

.no-webp .bottom-row-right {
	background-image: url('../images/team.jpg');
}

/*** End Of Bottom Content ***/
/*** Cash Content ***/
#content-cash {
	position: absolute;
	z-index: 100;
	width: 1024px;
	margin-top: 80px;
}

.row-container {
	width: 683px;
	margin-top: 80px;
	margin-right: 0;
	color: #fff;
	margin-left: auto;
}

.row-container .divider {
	margin: 60px 0px 30px 0px;
	width: 20%;
	height: 4px;
	background: #fff;
	border: none;
}

.row-container h3.montSemiBold {
	font-size: 34px;
	margin: 15px 0 30px 0;
	font-family: 'Montserrat Bold';
}

.row-container h3 {
	font-size: 36px;
	font-weight: normal;
}

.row-container p {
	font-size: 16px;
}

.cash-row {
	float: left;
	width: 28%;
	border-radius: 20px;
	background: #ffffff;
	padding: 0 40px 30px 40px;
	-webkit-box-shadow: -2px 10px 15px -1px rgba(0, 0, 0, 0.31);
	-moz-box-shadow: -2px 10px 15px -1px rgba(0, 0, 0, 0.31);
	box-shadow: -2px 10px 15px -1px rgba(0, 0, 0, 0.31);
	margin-right: 42px;
}

.cash-row h3 {
	margin-top: 18px;
	margin-bottom: 15px;
	font-size: 16px;
	color: #c7a652;
}

.cash-row p {
	color: #3f3f3f;
	font-size: 14px;
	font-family: 'Montserrat Light';
	line-height: 2em;
}

.cash-row img {
	width: 25%;
	background: url('../images/brown-overlay.png');
	padding-top: 50px;
}

/*** End of Cash Content ***/
/*** Space White ***/
#space-white,
#companies {
	background: #fff;
	min-height: 195px;
	clear: both;
	float: none;
	width: 100%;
	margin: 0 auto;
}

#space-white {
	padding-right: 52px;
}

/*** End of Space White ***/
/*** Contact Form Section ***/
#content-contact .inner-big {
	display: flex;
}

.left-contact-row,
.right-contact-row {
	flex: 1;
}

/*** End Contact Form Section ***/
/*** Team Content ***/
#team-content {
	background: #ebebeb;
	display: inline-block;
	width: 100%;
	padding-top: 100px;
	margin-top: 100px;
}

#team-content hr {
	margin-left: auto;
	margin-right: auto;
}

#team-content h3 {
	text-align: center;
	font-size: 32px;
	color: #000;
}

.mail-icon {
	width: 5%;
	padding-bottom: 9px;
	margin-right: 5px;
}

.members-slider {
	width: 92%;
	float: left;
	margin-bottom: 100px;
}

.member {
	width: 23%;
	float: left;
	margin-right: 20px;
	background: #ffffff;
}

.member-info {
	padding: 25px 20px 60px 20px;
	clear: both;
	overflow: hidden;
}

.member-info h4 {
	font-size: 20px;
	font-weight: 600;
	padding-bottom: 12px;
	color: #333333;
}

.member-info p,
.member-info a {
	font-size: 14px;
	color: #929292;
}

.member-info p {
	margin-bottom: 18px;
	font-size: 14px;
	color: #808080;
}

.member-info a {
	font-weight: normal;
}

.member-info a:hover {
	text-decoration: none;
	color: #c7a652;
}

.member-controls {
	width: 8%;
	float: left;
	padding-top: 118px;
	padding-left: 40px;
}

.member-controls p a {
	font-size: 50px;
}

.member-controls a:hover {
	text-decoration: none;
}

.email-info {
	padding: 10px 0px 10px 0px;
}

.team-pic {
	width: 100%;
}

.social-media img {
	width: 80%;
}

.social-media {
	padding-top: 5px;
}

.social-media ul {
	list-style: none;
}

.social-media ul li {
	float: left;
	padding-right: 5px;
}

.social-media ul li img:hover {
	filter: contrast(0);
	-webkit-filter: contrast(0);
}

/*** END OF TEAM CONTENT ***/
/*** COMPANIES ***/
#companies1 {
	padding: 65px 0 65px 0;
}

#companies1 ul {
	list-style: none;
	margin: 0 auto;
	width: 90%;
	margin: 0 auto;
}

#companies1 ul li {
	float: left;
	padding: 20px;
}

#companies1 ul li img {
	width: 70%;
}

#companies2 {
	background: #000000;
	display: inline-block;
	width: 100%;
	padding: 65px 0 65px 0;
}

#companies2 ul {
	list-style: none;
	margin: 0 auto;
	width: 90%;
	margin: 0 auto;
}

#companies2 ul li {
	float: left;
	padding: 15px;
	text-align: center;
}

#companies2 ul li img {
	width: 65%;
}

/*** End Companies ***/
/*** Footer Links ***/
#footer-links {
	width: 100%;
	background: #1a1a1a;
	display: inline-block;
	display: flex;
	flex-wrap: wrap;
	padding-top: 80px;
	padding-bottom: 80px;
}

#footer-links .divider {
	margin: 0 0 40px 0;
	width: 28%;
	background: #c7a652;
	height: 3px;
	border: none;
}

.left-column .mid-row ul,
.right-column ul {
	list-style: none;
	text-align: left;
}

#footer-links h4 {
	color: #fff;
	font-size: 30px;
	font-weight: normal;
	margin: 0;
}

#footer-links .mid-row {
	padding-top: 0;
	padding-left: 0;
	width: 30%;
}

.right-column ul li,
.left-column ul li a {
	color: #fff;
	font-size: 14px;
	line-height: 3.5em;
	font-weight: normal;
}

.right-column ul li i {
	padding-right: 10px;
	font-size: 18px;
	position: relative;
	top: 3px;
}

.right-column ul li:nth-child(2) i {
	left: 3px;
	font-size: 24px;
}

.right-column ul li:nth-child(4) i {
	padding-right: 8px;
}

.left-column {
	float: left;
	width: 70%;
}

.right-column {
	float: left;
	width: 30%;
}

footer {
	width: 100%;
	min-height: 50px;
	background: #0e0e0e;
	color: #fff;
	text-align: center;
	font-size: 16px;
	justify-content: center;
	align-items: center;
	display: flex;
}

footer p {
	margin: 0;
	font-size: 12px;
}

#footer-links .col-left,
#footer-links .col-right {
	text-align: center;
}

/*** SNIPPET CLASSES ***/
.content-divider {
	background: #c7a652;
	height: 2px;
	width: 8%;
	margin: 20px 0 30px 0;
	border-radius: 4px;
}

.center-content-divider {
	background: #c7a652;
	height: 2px;
	width: 8%;
	border-radius: 4px;
	margin: 20px auto 0 auto;
}

#content .center-content-text p {
	padding-bottom: 40px;
	margin-bottom: 0px;
}

.border-box {
	width: 100%;
	border: 4px solid #c7a652;
	padding: 0px 0px 0px 20px;
	margin: 45px 80px 0px 0px;
	background: #c7a65230;
	border-radius: 5px;
}

.featured img {
	height: 100%;
	width: 100%;
}

.row-regular {
	width: 1366px;
	margin: 0 auto;
}

.row-medium {
	width: 1280px;
	margin: 0 auto;
}

.item-box {
	width: 100%;
	clear: both;
	margin: 0 auto;
}

.item-box img {
	width: auto;
}

#banner-mid .row-regular {
	position: relative;
	height: 100%;
}

.bottom-center-content {
	position: absolute;
	bottom: 0;
	left: 15%;
	right: 15%;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.text-white {
	color: #fff;
}

.text-black {
	color: #000;
}

.video-content {
	background: #000;
	width: 100%;
	position: relative;
	margin: 60px auto auto auto;
	height: 576px;
}

.video-content iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: -80px;
	left: 0;
}

.video-content h2 {
	font-size: 20px;
	color: #c7a652;
	text-align: center;
	position: absolute;
	bottom: 20px;
	left: 10%;
	right: 10%;
}

#space-white h5 {
	font-size: 16px;
	color: #000000;
	text-align: right;
	padding-top: 18px;
	font-family: Montserrat;
	font-weight: 400;
}

.btn {
	width: 253px;
	height: 73px;
	line-height: 73px;
	text-align: center;
	background: #fff;
	border-radius: 40px;
	color: #000;
	font-size: 20px;
	border: 0;
	cursor: pointer;
	font-family: 'Montserrat SemiBold';
}

.btn:hover {
	text-decoration-line: none;
	color: #c7a652;
}

.btn-gold {
	background: #c7a652;
	color: #fff;
}

.btn-gold:hover {
	background: #fff;
	color: #000;
}

hr.divider-2 {
	height: 4px;
	border: none;
	margin: 30px 0 24px 0;
	width: 100px;
	border-radius: 5px;
}

hr.divider-gold {
	background-color: #c7a652;
}

hr.divider-white {
	background-color: #ffffff;
	text-align: left;
}

hr.divider-bottom {
	margin: 16px 0 40px 0;
	text-align: left;
}

.forms-quote hr.divider-bottom {
	margin-bottom: 0;
}

hr.divider-centered {
	margin-left: auto;
	margin-right: auto;
}

#frm-contact .divider-2,
#content hr.divider-2 {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

#frm-contact .divider-2 {
	margin-top: 0;
	margin-bottom: 0;
}

#footer-links .left-column .divider-2 {
	width: 180px;
}

#footer-links .right-column .divider-2 {
	width: 120px;
}

.row-container .divider-2 {
	margin-bottom: 24px;
}

.divider-left {
	background-color: #c7a652;
	height: 4px;
	border: none;
	margin: 20px 0 30px 0;
}

.content-header {
	font-size: 54px;
}

.content-text {
	line-height: 2em;
	font-size: 16px;
	color: #808080;
	padding-top: 20px;
	padding-bottom: 40px;
}

.box-text {
	color: #808080;
	padding-top: 15px;
	padding-bottom: 10px;
	line-height: 1.5em;
	font-size: 15px;
}

.scrollable-list-header {
	color: #000000;
	font-size: 18px;
}

.scrollable-content p {
	color: #000;
	font-size: 16px;
}

.scrollable-content h3 {
	font-family: 'Montserrat Bold';
	line-height: 1.6em;
	margin-bottom: 28px;
}

.scrollable-content a {
	color: #957433;
	font-size: 16px;
	font-weight: 700;
	font-family: 'Montserrat';
}

.scrollable-content a:hover {
	text-decoration: underline;
}

.content-bottom-divider {
	height: 4px;
	width: 15%;
	background: #c7a652;
	border-radius: 2px;
	border: none;
}

.bottom-center-content h4 {
	font-size: 50px;
	font-family: 'Montserrat Medium';
	font-weight: 400;
}

.bottom-content-text h4 {
	margin-bottom: 80px;
	margin-top: 20px;
	font-size: 26px;
}

.bottom-content-text span {
	color: #c1a04b;
}

.bottom-content-text p {
	line-height: 1.8em;
	font-size: 16px;
}

.bottom-seal-content {
	padding-bottom: 40px;
}

.seal-img {
	width: 35%;
}

.seal-img img {
	width: 100%;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.seal-text {
	width: 65%;
	padding-right: 60px;
	padding-left: 20px;
	padding-top: 44px;
}

.seal-text p {
	font-size: 15px !important;
	text-align: left;
	line-height: 1.4em;
	padding-bottom: 16px;
}

.seal-bottom-text {
	width: 100%;
	padding-right: 60px;
	line-height: 1.2em;
	text-align: justify;
	padding-top: 30px;
}

.seal-bottom-text p {
	font-size: 16px !important;
}

.col-left {
	width: 50%;
	float: left;
}

.col-right {
	width: 50%;
	float: right;
}

.col-img {
	width: 100%;
	height: auto;
}

.cta-box {
	position: relative;
	height: 100%;
}

.webp .cta-box-fullwidth {
	display: inline-block;
	text-align: left;
	width: 100%;
	position: relative;
	background-image: url('../images/do-not-take-the-risk-choose-reliable-securecash-service-image-1.webp');
	background-size: cover;
	background-repeat: no-repeat;
	height: 269px;
}

.no-webp .cta-box-fullwidth {
	display: inline-block;
	text-align: left;
	width: 100%;
	position: relative;
	background-image: url('../images/do-not-take-the-risk-choose-reliable-securecash-service-image-1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	height: 269px;
}

.cta-box-fullwidth-overlay {
	width: 100%;
	position: absolute;
	top: 0px;
	padding: 40px;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.cta-overlay {
	width: 70%;
	position: absolute;
	top: 0px;
	background: rgba(0, 0, 0, 0.5);
	padding: 30px;
}

.cta-overlay h1 {
	font-family: 'Montserrat Bold';
	color: #fff;
	line-height: 1.2em;
}

.cta-overlay h2 {
	font-family: 'Montserrat Bold';
	color: #fff;
	line-height: 1.2em;
	font-size: 36px;
}

.cta-box-fullwidth h3 {
	font-family: 'Times New Roman';
	line-height: 1.2em;
	font-weight: 400;
	font-size: 36px;
	padding-bottom: 30px;
	padding-top: 20px;
	text-align: center;
	color: #fff;
}

.cta-box-fullwidth p {
	font-family: 'Montserrat Light';
	font-size: 18px;
	text-align: center;
	color: #fff;
}

.cta-box-fullwidth .divider {
	margin: 0 auto;
	width: 30%;
	height: 2px;
	border: none;
	border-radius: 2px;
	background: #c1a04b;
}

.cta-overlay .divider {
	margin-left: 0px;
	width: 30%;
	border: none;
	border-radius: 2px;
}

.cta-overlay p {
	color: #fff;
	padding-bottom: 0px;
	font-family: 'Montserrat Regular';
}

.cta-overlay h3 {
	color: #fff;
}

.cta-btn {
	background: #c3a252;
	width: 100%;
	height: 100%;
	padding: 18px;
	color: #fff;
	display: block;
	text-align: center;
	font-family: 'Montserrat Medium';
	font-size: 20px;
	border: 2px solid #c3a252;
}

.cta-btn .featured-button-active:hover {
	background: #fff;
	color: #000;
}

/* content sections */
#intro {
	max-width: 1366px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: center;
	align-items: center;
}

#intro h2 {
	text-align: center;
	margin: 0 auto;
	line-height: 45px;
	max-width: 80%;
	color: #000;
}

#intro .content-wrapper {
	width: 80%;
	margin-top: 50px;
	padding: 0;
}

#intro .content-wrapper p {
	text-align: center;
	color: #000;
}

#pitch {
	max-width: 1366px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

#pitch .scroller {
	height: 770px;
	direction: rtl;
	color: #000;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: #ffffff;
}

#pitch div.col-left div div ul {
	list-style-type: none;
	width: 80%;
	margin: 0 auto;
}

#pitch div.col-left div div ul li {
	font-family: 'Montserrat Light';
	font-size: 16px;
	line-height: 2em;
	text-indent: -2.2em;
}

#pitch div.col-left div div ul li strong {
	font-family: 'Montserrat Light';
}

.scroller::-webkit-scrollbar {
	width: 10px;
	background: #808080;
	border-radius: 20px;
}

.scroller::-webkit-scrollbar-thumb {
	background: #808080;
	border-radius: 20px;
}

.scroller::-webkit-scrollbar-track {
	background: #ebebeb;
	border-radius: 20px;
}

#pitch .scrollable-content {
	direction: ltr;
	margin: 0 10%;
}

#pitch .scrollable-content h3 {
	text-align: left;
	margin-bottom: 35px;
	margin-top: 65px;
}

#pitch .scrollable-content h3:nth-child(1) {
	margin-top: 10px;
}

#faq .scroller .scrollable-content {
	background-color: #fff;
}

#pitch .cta-overlay {
	width: 55%;
	background: #000000;
	padding: 60px 30px;
	display: flex;
	flex-direction: column;
}

#pitch .col-right p {
	text-align: left;
	font-size: 25px;
	margin-bottom: 0;
	padding-bottom: 0px;
}

#pitch .col-right p.center {
	text-align: center;
	padding-bottom: 20px;
}

#pitch a.btn {
	width: 100%;
	margin-top: 18px;
}

#video-section {
	width: 100%;
	position: relative;
	max-height: 680px;
	height: 630px;
}

#video-section h2 {
	font-size: 16px;
	color: #000000;
	text-align: center;
	position: relative;
	z-index: -1;
	margin: 16px auto;
	font-weight: 400;
	font-family: 'Montserrat';
}

#video-section div.black-bar {
	background-color: #1a1a1a;
	width: 100%;
	top: 0;
	height: 400px;
	left: 0;
}

#video-section div.video-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

#video-section div.video-container .video-player {
	width: 100%;
	height: 576px;
}

#faq {
	max-width: 1366px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	height: 545px;
}

#faq .col-left {
	height: 100%;
	flex-grow: 1;
}

#faq .col-left .cta-box {
	width: 90%;
}

#faq .col-left .cta-box img {
	height: 100%;
	width: 100%;
}

#faq .col-left .cta-box div {
	display: flex;
	height: 80%;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
	background: #000000;
	padding: 0 30px;
	justify-content: center;
	align-content: center;
	width: 70%;
}

#faq h3.header-text,
#guarantee h3.header-text,
#pitch h3.header-text {
	color: #fff;
	font-size: 33px;
	line-height: 48px;
	font-family: 'Montserrat Bold';
}

#faq hr.divider {
	width: 25%;
	margin: 0;
	margin-top: 20px;
}

#faq .col-right {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
}

#faq .scroller {
	height: 100%;
	direction: ltr;
	color: #000;
	overflow-x: hidden;
	overflow-y: scroll;
}

#faq .scroller .scrollable-content {
	direction: ltr;
	padding-right: 90px;
	margin: 0, 10% 0 0;
}

#faq .scroller .scrollable-content ul {
	list-style: none;
}

#faq .scroller .scrollable-content ul li {
	margin-bottom: 30px;
}

#faq h4 {
	font-size: 20px;
	margin-bottom: 14px;
	font-family: 'Montserrat Bold';
	color: #000;
}

#guarantee-seal-wrapper {
	display: flex;
	flex-direction: row;
	margin-bottom: 60px;
}

#guarantee {
	max-width: 1366px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

#guarantee .content-wrapper {
	display: flex;
	flex-direction: column;
	width: 90%;
	padding-top: 0;
	margin: 0;
	padding-left: 10px;
}

#guarantee .col-left .content-wrapper img {
	width: 300px;
	height: 230px;
}

#guarantee .col-left .content-wrapper p {
	padding-bottom: 20px;
	font-family: 'Montserrat';
}

#guarantee .content-wrapper #guarantee-seal-wrapper div {
	width: 100%;
	text-align: center;
	justify-content: space-around;
	height: auto;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 20px 0;
}

#guarantee .col-left .content-wrapper p.note {
	font-family: 'Montserrat Thin 300 Italic';
	margin-bottom: 60px;
}

#guarantee .col-left .content-wrapper p a {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 700;
	font-style: italic;
	color: #957433;
}

#guarantee .col-left .content-wrapper p a:hover {
	text-decoration: underline;
}

#guarantee .col-left .content-wrapper p strong {
	font-family: 'Montserrat Light';
}

#guarantee a.btn {
	width: 100%;
	margin-top: 18px;
}

#logo-text {
	font-size: 40px;
	font-weight: 500;
	font-family: 'Times New Roman Regular';
	margin-bottom: 0;
}

#logo-text span {
	color: #c7a652;
}

#guarantee .content-wrapper div .slogan {
	font-family: 'Montserrat Bold';
	line-height: 28px;
	font-size: 20px;
	padding-bottom: 0;
}

.scrollable-content ul li::before {
	content: '';
	display: inline-block;
	height: 15px;
	width: 20px;
	background-image: url(../images/icons/check.png);
	background-size: contain;
	background-repeat: no-repeat;
	padding-right: 14px;
}

.scrollable-content ul li h4 {
	display: inline-flex;
}

.scrollable-content ul li p {
	padding: 0 0 0 40px;
}

#guarantee .col-right {
	width: 50%;
	float: right;
	display: flex;
	justify-content: flex-end;
	align-self: center;
}

#guarantee .col-right .cta-box {
	width: 90%;
}

#guarantee .col-right .cta-box .cta-overlay {
	width: 70%;
	padding: 60px 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#guarantee .col-right .cta-box .cta-overlay p {
	text-align: left;
	font-size: 25px;
}

#guarantee .col-right .cta-box .cta-overlay p span {
	text-decoration: underline;
}

/* Utils */
.spacer-sm {
	display: block;
	height: 25px;
}

.spacer-md {
	display: block;
	height: 50px;
}

.spacer-lg {
	display: block;
	height: 100px;
}

/* Colors */
.black {
	background-color: #000;
}

.white {
	background-color: #fff;
}

#breadcrumb-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
}

.breadcrumb {
	list-style: none;
	overflow: hidden;
}

.breadcrumb li {
	float: left;
}

.breadcrumb li {
	font-family: 'Montserrat Light';
	font-size: 14px;
	font-weight: 500;
	padding: 5px 0;
}

.breadcrumb li::after {
	content: '>';
	margin-right: 5px;
	margin-left: 5px;
	color: #fff;
	text-decoration: none;
	text-decoration-line: none;
}

.breadcrumb li:last-child::after {
	display: none;
}

.breadcrumb li {
	color: #ffffff;
}

.p-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 16px;
	margin-top: 50px;
	font-size: 22px;
	font-family: 'Montserrat SemiBold';
}

.p-header .icon-service {
	height: 40px;
	padding-right: 16px;
}

div.featured {
	display: none;
}

#breadcrumb-section {
	width: 100%;
	background: #1a1a1a;
	display: inline-block;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	background: #1a1a1a;
	display: inline-block;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

#intro-more-content {
	display: block;
}

.read-more-link {
	display: none;
}

.show {
	display: block;
}

/* quote page */
.in-full {
	width: 100%;
	background-color: blue;
}

.bg-dots {
	background: url('../images/bg-quote-header-left.png') left center no-repeat,
		url('../images/bg-quote-header-right.png') right center no-repeat;
	width: 100%;
}

.bg-gray {
	background-color: #f4f3f2;
}

#headline-content {
	width: 100%;
	height: 572px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
	flex-direction: column;
}

#headline-wrapper {
	text-align: center;
}

#headline-wrapper h1 {
	font-size: 88px;
	line-height: 1em;
	margin-bottom: 24px;
}

#headline-wrapper h3.prata {
	font-size: 40px;
	line-height: 1em;
	color: #4d4d4d;
	margin-bottom: 24px;
}

#headline-wrapper hr {
	margin: 0 auto 24px auto;
}

#headline-wrapper p {
	font-size: 32px;
	font-family: 'Montserrat';
	color: #4d4d4d;
	padding-bottom: 0;
}

#quote-content {
	background: url('https://securecash.com.au/images/secure-cash-head-office-2.png'),
		url('../images/bar-quote.png'), url('../images/bg-body-left.png'),
		url('../images/bg-body-right.png');
	background-size: auto, 100% 359px, auto, auto;
	background-repeat: no-repeat;
	margin-bottom: 40px;
	background-position: -moz-calc(((100% - 1366px) / 2) + 424px) 41px, left top,
		left bottom, right bottom;
	background-position: -webikit-calc(((100% - 1366px) / 2) + 424px) 41px,
		left top, left bottom, right bottom;
	background-position: calc(((100% - 1366px) / 2) + 424px) 41px, left top,
		left bottom, right bottom;
}

/* ( (100% - 1366px) / 2 ) +  ( (1366px / 2 ) / 2 ) + 100px ) */
#quote-content-container {
	display: flex;
}

#quote-content-left,
#quote-content-right {
	flex: 1;
	overflow: hidden;
}

#quote-content-left {
	text-align: center;
	flex: 1.5;
}

#quote-content-left h3 {
	text-align: center;
	font-weight: 600;
	font-size: 40px;
	margin-bottom: 8px;
	line-height: 1.4em;
	margin-top: -10px;
}

#quote-content-left hr.divider-bottom {
	margin: 0 auto;
}

#contact-info-wrapper {
	display: flex;
	padding-bottom: 36px;
}

.icon-address::before {
	background-image: url(../images/icons/ic-address.png);
}

.icon-email::before {
	background-image: url(../images/icons/ic-email.png);
}

.icon::before {
	content: '';
	display: inline-block;
	height: 15px;
	width: 20px;
	padding-right: 14px;
	background-size: contain;
	background-repeat: no-repeat;
}

#left-content-text-wrapper {
	padding: 40px 8px 0 8px;
	display: flex;
	flex-direction: column;
	margin-top: 360px;
}

#left-content-text-wrapper h4 {
	font-size: 24px;
	line-height: 1.4em;
}

#left-content-text-wrapper h4.icon {
	font-weight: 400;
}

#left-content-text-wrapper p {
	font-family: 'Montserrat Light';
	line-height: 2em;
	margin-bottom: 8px;
}

.contact-info-item {
	max-width: 50%;
}

.contact-info-item p {
	line-height: 2em;
}

#left-content-text-wrapper p {
	/* padding-right: 16px; */
}

div.callback-button {
	width: 200px;
	background-color: #c7a652;
	text-align: center;
	padding: 16px 8px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50px;
}

div.callback-button a {
	margin-bottom: 0px;
	color: white;
	font-family: 'Montserrat';
	padding: 0;
	font-size: 16px;
}

div.callback-button:hover a {
	color: #ffffff;
	text-decoration: none;
}

div.callback-button:hover {
	background-color: black;
	cursor: pointer;
}

#quote-content-right > #frm-contact {
	margin-top: 56px;
	width: 100%;
	float: none;
}

#quote-content-right > #step-dots-container {
	margin-right: 0;
	width: 100%;
	margin-left: auto;
}

#left-content-header-wrapper {
	margin-bottom: 36px;
}

#contact-info-wrapper {
	min-height: 250px;
	text-align: left;
}

#note-wrapper {
	margin-bottom: 24px;
	text-align: center;
}

#contact-info-wrapper > .mid-row-divider-wrapper {
	background: transparent;
	flex: 1;
	align-items: flex-end;
	display: flex;
	margin-left: 16px;
	margin-right: 16px;
}

#contact-info-wrapper > .mid-row-divider-wrapper > .mid-row-divider {
	align-self: flex-end;
	background-color: #dddddd;
	width: 1px;
	height: -moz-calc(100% - 48px);
	height: -webikit-calc(100% - 48px);
	height: calc(100% - 48px);
}

#bg-gray-company-slider > #companies1,
#bg-gray-company-slider > #companies1 > .slider {
	background-color: #f0f0f0;
}

#quote-content-left > img {
	height: 320px;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
}

.checkbox-wrapper-4,
.checkbox-wrapper-5,
.checkbox-wrapper-6 {
	justify-self: center;
	width: 80%;
}

.checkbox-wrapper-7,
.checkbox-wrapper-8,
.checkbox-wrapper-9 {
	justify-self: end;
	width: 108px;
}

#about-us-team-slider
	.team-carousel-wrapper
	.team-carousel
	.team-item-wrapper
	.team-item-about-content {
	display: flex;
}

#about-us-team-slider .team-carousel-wrapper .team-about-us-profile-label {
	display: none;
}

#about-us-team-slider
	.team-carousel
	.team-item-wrapper
	.item-container
	.member-info
	h4 {
	margin-bottom: 8px;
}

.section-content::-webkit-scrollbar-thumb {
	background: #808080;
	border-radius: 20px;
}

.section-content::-webkit-scrollbar-track {
	background: #ebebeb;
	border-radius: 20px;
}

.section-content::-webkit-scrollbar {
	width: 10px;
}

#hero-image .wrapper {
	width: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
}

#hero-image .wrapper h3 {
	font-family: 'Montserrat';
	font-size: 40px;
	line-height: 1.4em;
	margin-bottom: 13px;
}

#hero-image .wrapper h1 {
	font-size: 56px;
	line-height: 1.4em;
}

#hero-image .wrapper p {
	font-family: 'Montserrat';
	text-align: center;
	width: 55%;
	line-height: 2;
	font-size: 16px;
}

#hero-image .wrapper hr {
	margin-top: 21px;
	margin-bottom: 24px;
}

.form-panel {
	display: flex;
	justify-content: center;
	align-items: center;
}

#contact-form-section {
	margin-top: 120px;
	margin-bottom: 104px;
}

#contact-form-content-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 0 8px 8px 8px;
	background-color: #f1f1f1;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#contact-form-info-panel {
	background-color: #000000;
	width: 40%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	position: relative;
}

#contact-form-form-panel {
	background-color: #f1f1f1;
	width: 60%;
	border-radius: 0 8px 8px 0;
}

#contact-form-info-panel .content {
	width: auto;
	display: flex;
	flex-direction: column;
	color: white;
	position: absolute;
	top: 200px;
}

#contact-form-form-panel .content {
	width: 80%;
	margin: 56px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#contact-form-info-panel .content h3 {
	font-size: 32px;
	line-height: 1.4;
}

#contact-form-info-panel .content hr {
	margin: 26px 0 88px 0;
	padding: 0;
}

#contact-form-info-panel .content .info-wrapper {
	margin-bottom: 56px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

#contact-form-info-panel .content .info-wrapper .ic-wrapper {
	text-align: center;
	width: 25px;
	margin-right: 24px;
}

#contact-form-info-panel .content .info-wrapper .ic-wrapper .fa {
	font-size: 24px;
	color: #c7a652;
}

#contact-form-form-panel .content textArea,
#contact-form-form-panel .content input {
	width: 100%;
	padding: 16px;
	border: none;
	font-size: 16px;
	font-family: 'Montserrat Light';
}

#contact-form-form-panel .content .control-wrapper .chkbox {
	width: 100%;
}

#contact-form-form-panel .content input,
#contact-form-form-panel .content .callback-button {
	margin-top: 32px;
}

#contact-form-form-panel .content textArea {
	margin-top: 56px;
}

#contact-form-form-panel .content .callback-button {
	margin-left: 0px;
}

#contact-form-form-panel .select-box-section .input-select-department-wrapper {
	width: 100%;
	border: 1px solid #b9984b;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
}

#contact-form-form-panel
	.select-box-section
	.input-select-department-wrapper
	.ic-carret {
	background-image: url('https://securecash.com.au/images/contact-page/select-box-cerret-down.png');
	background-repeat: no-repeat;
	background-position: center center;
	width: 14px;
	margin-left: -24px;
}

#contact-form-form-panel .select-box-section {
	width: 100%;
	background-color: #000;
	padding: 8px;
	height: 52px;
	border-radius: 0;
}

.input-select,
#input-select-department {
	text-align: center;
	color: #fff;
	background-color: transparent;
	text-align-last: left;
	font-size: 16px;
	font-family: 'Montserrat Light';
	border-radius: 0;
	height: 100%;
	border: 0;
	padding-left: 16px;
	padding-right: 32px;
	width: 220px;
	z-index: 999;
	/* line-height: 2em; */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	/* Safari 3-4, iOS 1-3.2, Android 1.6- */
	-moz-border-radius: 0;
	/* Firefox 1-3.6 */
	border-radius: 0;
	outline: none;
	text-align-last: center;
}

.input-select {
	width: 170px;
	padding-left: 0px;
}

.input-select:focus > option:checked,
#input-select-department:focus > option:checked {
	background: #c7a652 !important;
	color: #fff;
}

.input-select:focus > option:hover,
#input-select-department:focus > option:hover {
	background: #c7a652 !important;
}

.input-select option,
#input-select-department option {
	font-family: 'Montserrat';
	color: #000;
}

#contact-testimonial-section {
	margin-bottom: 120px;
}

#testimonial-header-wrapper {
	width: 50%;
	text-align: center;
	margin: 0 auto 32px auto;
}

#testimonial-header-wrapper hr {
	margin: 16px auto 24px auto;
}

#testimonial-header-wrapper h3 {
	font-size: 32px;
	font-family: 'Montserrat Bold';
	line-height: 2em;
}

#testimonial-header-wrapper p {
	font-family: 'Montserrat Light';
	line-height: 2em;
	font-size: 16px;
}

#testimonial-carousel-container {
	overflow: hidden;
}

#testimonial-carousel-container .scroll-control-mobile {
	display: none;
}

#testimonial-carousel {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	transition: 1s;
	left: 0px;
	padding-bottom: 30px;
}

#testimonial-carousel .item {
	display: flex;
	flex-direction: row;
	min-width: 100%;
}

#testimonial-carousel .item .content {
	width: 100%;
}

#testimonial-carousel .item .content .wrapper {
	width: 90%;
	margin: auto;
	height: calc(100% - 30px);
	position: relative;
	margin-right: 0;
}

#testimonial-carousel .item .content .wrapper .info .name {
	margin-bottom: 4px;
}

#testimonial-carousel .item .content .wrapper .info p {
	font-family: 'Montserrat Light';
}

#testimonial-carousel .item .content .wrapper .excerpt {
	margin-top: 43px;
	height: 200px;
	/* overflow: scroll; ERNY ERNY */
}

#testimonial-carousel .item .content .wrapper .excerpt p {
	font-family: 'Montserrat Light';
	line-height: 1.8em;
	font-size: 16px;
}

#testimonial-carousel .item .photo-section {
	display: none;
	position: relative;
	width: 40%;
}

#testimonial-carousel .item .photo-section .quote {
	position: absolute;
	font-size: 240px;
	font-family: 'Montserrat Black';
	color: #cbcbcb;
	top: 30px;
	left: 70px;
	line-height: 0 !important;
	padding: 0;
	margin: 0;
	z-index: 5000;
}

#testimonial-carousel .item .photo-section .photo {
	position: relative;
	overflow: hidden;
	height: calc(100% + 30px);
	width: calc(100% + 30px);
}

#testimonial-carousel .item .photo-section .photo .profile {
	width: calc(100% - 30px);
	height: calc(100% - 30px);
	z-index: 9999;
}

#testimonial-carousel .item .photo-section .photo:after {
	content: '';
	position: absolute;
	width: 190%;
	height: 200%;
	right: -125%;
	top: 14%;
	background: #b9984b;
	z-index: -1;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#testimonial-carousel .item .photo:before {
	position: absolute;
	top: 0;
	left: 40px;
	font-size: 56px;
	font-family: 'Montserrat Black';
}

#testimonial-carousel .scroll-control {
	display: flex;
	flex-direction: row;
	height: 80px;
	width: 100px;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
}

#testimonial-carousel-container .scroll-control-mobile div p,
#testimonial-carousel .scroll-control div p {
	font-family: 'Montserrat';
	font-size: 40px;
	line-height: 1em;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

#testimonial-carousel .scroll-control div.divider {
	height: 100%;
	width: 2px;
	background-color: #b9984b;
	margin: 0;
	padding: 0;
	border-radius: 0px;
}

#mapContainer {
	width: 100%;
	height: 500px;
}

.input-select option:hover,
#input-select-department option:hover {
	box-shadow: 0 0 10px 100px #c7a652 inset;
}

#callback-option-container {
	position: relative;
	display: none;
	margin-top: 56px;
	transition: all 2s;
}

#contact-form-content-wrapper .control-wrapper {
	margin-top: 32px;
	width: 100%;
}

#contact-form-form-panel .control-wrapper .chkbox input {
	margin-top: 0px;
}

#contact-form-form-panel .control-wrapper .chkbox label {
	display: flex;
}

#contact-form-form-panel .control-wrapper .chkbox label {
	display: flex;
}

#contact-form-form-panel .control-wrapper .chkbox label span {
	margin-top: 0px;
}

#contact-form-form-panel .control-wrapper .chkbox label span.label {
	line-height: 1.8em;
	height: auto;
	width: auto;
	margin: 0;
	padding: 0;
	background: transparent;
	border-radius: 0;
	flex: 1;
	font-family: 'Montserrat Light';
}

#contact-form-form-panel
	.control-wrapper
	.chkbox
	input[type='checkbox']:checked
	+ label:after {
	top: 1px;
}

/* Calendar style */
#contact-form-form-panel
	.content
	#callback-option-container
	.form-input-wrapper
	.calendar {
	margin-top: 8px;
}

#contact-form-form-panel .content #callback-option-container input {
	margin-top: 16px;
}

#contact-form-form-panel
	.content
	#callback-option-container
	.select-box-section {
	margin-top: 8px;
}

#contact-form-form-panel .content #callback-option-container label {
	font-family: 'Montserrat Light';
	line-height: 2em;
}

#contact-form-form-panel
	.content
	#callback-option-container
	.form-input-wrapper {
	margin-top: 32px;
	margin-top: 32px;
	flex: 1;
	margin: 0 10px;
}

#select-callback-pref {
	display: flex;
	flex-direction: row;
	margin-top: 16px;
}

.hello-week .navigation {
	position: relative;
}

.hello-week .navigation .next,
.hello-week .navigation .prev {
	padding: 0;
	margin: 0;
	position: relative;
	color: #c7a652;
}

.hello-week .week {
	color: #c7a652 !important;
}

.hello-week .month .day.is-today {
	background-color: #000000;
}

.hello-week .month .day.is-selected {
	background-color: #c7a652 !important;
}

/* Calendar style End*/
/* welcome page */
#welcome-header-section {
	height: 81vh;
	width: 100%;
	position: relative;
}

#welcome-header-section .inner {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	flex-wrap: wrap;
}

#welcome-header-text-wrapper {
	position: relative;
	margin-top: 54px;
	width: 50%;
}

#header-image-wrapper {
	width: 50%;
}

#welcome-header-image {
	position: absolute;
	width: 100%;
	height: 100%;
	background: url(../images/welcome/welcome-team.png) center center no-repeat;
	background-size: cover;
	top: 0;
	right: 0;
	filter: brightness(0.9);
}

#welcome-header-text-wrapper h3.prata2 {
	font-size: 154px !important;
	margin-bottom: 16px;
	color: #a7a7a7;
	position: absolute;
	top: -50px;
	left: -120px;
}

#welcome-header-text-wrapper h1 {
	font-size: 72px;
	color: #c7a652;
	line-height: 1em;
	font-weight: 800;
	position: absolute;
	z-index: 10;
	top: 60px;
	left: 25px;
}

#welcome-header-text-wrapper p {
	color: #000;
	font-family: 'Montserrat Light';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.75em;
	margin-bottom: 48px;
	padding-right: 72px;
}

#welcome-header-text-wrapper hr {
	margin-top: 182px;
	margin-bottom: 30px;
}

.divider-dark {
	background: #000;
}

.btn-welcome-hero {
	background: #000;
	border-radius: 0;
	color: #ffff;
	font-family: 'Montserrat';
	font-size: 16px;
	padding: 21px 51px;
}

.btn-welcome-hero:hover {
	background: #c7a652;
	color: #000;
}

/* Welcome Hero Image */
.welcome-image-wrapper {
	padding-left: 40px;
	width: 50%;
}

.welcome-image-wrapper img {
	width: 110%;
	-webkit-box-shadow: -11px -9px 24px -16px black;
	-moz-box-shadow: -11px -9px 24px -16px black;
	box-shadow: -11px -9px 24px -16px black;
}

#header-image-wrapper .arrow-down {
	position: absolute;
	width: 60px;
	height: 60px;
	background-color: black;
	left: -25px;
	bottom: -25px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

#header-image-wrapper .arrow-down .ic-arrow-down {
	width: 100%;
	height: 30%;
	background: url(../images/welcome/down-arrow.png) center center no-repeat;
	background-size: contain;
}

.arrow-wrap {
	position: relative;
}

.arrow-wrap .arrow-down {
	position: absolute;
	width: 60px;
	height: 60px;
	background-color: black;
	left: -25px;
	bottom: -25px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.arrow-wrapr .arrow-down .ic-arrow-down {
	width: 100%;
	height: 30%;
	background: url(../images/welcome/down-arrow.png) center center no-repeat;
	background-size: contain;
}

i.fa.fa-long-arrow-down {
	color: #ffffff;
	font-size: 22px;
}

#instruction-section {
	position: relative;
	margin-top: 140px;
	margin-bottom: 140px;
}

#black-box {
	background-color: black;
	width: calc(100% / 2);
	height: auto;
	display: flex;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.instruction-section--black-box {
	justify-content: center;
}

#instruction-box span,
#black-box span {
	color: #c7a652;
	font-weight: 600;
}

.instruction-section--black-box--content {
	max-width: 531px;
	margin-right: 100px;
	padding-top: 98px;
	padding-bottom: 98px;
}

#black-box .content p {
	color: white;
	font-size: 16px;
	font-family: 'Montserrat Light';
	line-height: 2em;
}

.divider-gray {
	background: #7a7a7a;
}

#instruction-box {
	position: absolute;
	top: 80px;
	right: 0;
	margin-right: calc((100% - 1366px) / 2);
	background-color: white;
	max-width: calc((1366px / 2) + 100px);
	width: 100%;
	border-radius: 6px;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#instruction-box .content {
	padding: 56px;
}

#instruction-box .content .instruction-wrapper {
	position: relative;
	margin-top: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
}

#instruction-box .content h2 {
	color: #c7a652;
}

#instruction-box .content .instruction-wrapper p {
	font-family: 'Montserrat Light';
	font-size: 16px;
	line-height: 2em;
	z-index: 1;
	position: relative;
	padding-left: 29px;
}

#instruction-box .content div.instruction-wrapper div.counter {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 50px;
	height: 67px;
}

#instruction-box .content div.instruction-wrapper.quote div.counter {
	margin-left: 20px;
}

#instruction-box .content div.instruction-wrapper div.counter p {
	position: relative;
	height: 100%;
	font-family: 'Montserrat Black';
	font-size: 88px;
	line-height: 0.7em;
	color: #ededed;
	padding: 0;
	margin: 0;
}

#instruction-box .content div.instruction-wrapper.quote div.counter p {
	font-size: 160px;
}

#instruction-box .content div.instruction-wrapper.quote {
	padding-top: 32px;
	padding-bottom: 24px;
	margin-top: 64px;
}

#instruction-box .content .instruction-divider {
	border: 1px solid #ededed;
	width: 100%;
	margin-top: 40px;
}

.btn-gold-welcome {
	width: 248px;
	text-align: center;
	padding: 30px 22px;
	border-radius: 50px;
	display: block;
	margin: 0 auto;
	-webkit-box-shadow: 0px 0px 22px -5px #c7a652;
	-moz-box-shadow: 0px 0px 22px -5px #c7a652;
	box-shadow: 0px 0px 22px -5px #c7a652;
}

.btn-gold-welcome:hover {
	background: #000;
	color: #fff;
	text-decoration: none;
}

#instruction-box .content .callback-button {
	margin-top: 8px;
}

#ins-pane-trigger {
	cursor: pointer;
}

/* Homepage slider contrast. */
#banner #banner-slider .slideshow-container img {
}

#banner #banner-slider .slideshow-container h3.prata {
	text-shadow: 2px 2px 5px #111111;
	margin: 8px 0 24px 0;
}

#banner #banner-slider .slideshow-container h1.montSemi {
	text-shadow: 2px 2px 6px #111111;
	margin-bottom: 30px;
}

#banner #banner-slider .slideshow-container p {
	text-shadow: 2px 2px 5px #111111;
}

#banner #banner-slider .slideshow-container .d-btn {
	min-height: 60px;
	padding: 0 40px;
}

#banner #banner-slider .slideshow-container .d-btn p {
	text-shadow: none;
}

/* Content page/s adjustments (mostly seal). */
#intro hr.center-content-divider {
	height: 4px;
	border: none;
	border-radius: 5px;
}

#guarantee-seal-wrapper {
	margin: 0;
}

#guarantee .content-wrapper #guarantee-seal-wrapper div {
	justify-content: center;
}

#guarantee-seal-wrapper div h3 {
	margin: 0 0 16px 0;
}

#guarantee .col-left .content-wrapper p {
	padding: 0;
}

/* Quote form mobile responsive. */
@media only screen and (max-width: 1280px) {
	form.forms-quote {
		width: 100%;
	}
}

@media only screen and (max-width: 992px) {
	form.forms-quote {
		width: auto;
		max-width: auto;
		height: auto;
		padding: 30px;
		margin: 0 10px;
	}
	h5.checkboxHeaderText {
		margin: 0;
	}
	div.chkbox-container {
		margin: 0;
	}
}

.team-item-wrapper {
	height: 99.5%;
}

.team-item-about-content {
	align-items: initial;
}
.team-item-about-content .wrapper p {
	display: block;
	margin: 38px 32px;
	padding-right: 0;
	font-size: 15px;
	line-height: 32px;
}
.team-item-about-content .wrapper::before {
	content: '';
	height: 376px;
	width: 252px;
	display: inline-block;
	float: left;
	margin-right: 32px;
	margin-bottom: 14px;
}

/* end Orig */
